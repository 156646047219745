<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Busque um Sala 🔍">

      <b-row>
          <!--<b-col
            cols="6"
            lg="6">
        <b-card-text>No campo abaixo busque por: Email, Nome, Nickname</b-card-text>
        <b-form-input 
          icon-no-border  
          @keyup.enter.native="search()" 
          placeholder="Digite e pressione ENTER" 
          v-model="searchQuery" 
          class="input-rounded-full" 
          icon="icon-search" 
          icon-pack="feather"
          :state="docState"
          aria-describedby="input-live-help input-live-feedback"
          id="live-query"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>

      </b-col>-->
          <b-col
            cols="6"
            lg="3"
          >
       

</b-col></b-row>
<b-row><b-col>
  <b-table
      :items="datatable"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >


    <template #cell(actions)="data">
        <b-link :href="'/chat-room/'+ data.item.id">
          <feather-icon
            icon="EyeIcon"
            class="mr-50 text-primary"
          />
        </b-link>
      </template>
    </b-table>
</b-col></b-row>      </b-card>

    </div>
    <div v-else>
      <logo-loader-animation/>
    </div>
  </div>
  
</template>

<script>
import {     BTable,
BCard, BCardText, BLink, BFormInput, BFormInvalidFeedback, BFormSelectOption, BFormSelect, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import gql from 'graphql-tag'
import {_} from 'vue-underscore';
import LogoLoaderAnimation from "@/components/misc/LogoLoaderAnimation.vue"

export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormInvalidFeedback,
    underscore: _,
    LogoLoaderAnimation,
    BRow,
    BCol,
    BFormSelectOption,
    BFormSelect,
    BFormGroup

  },
  data () {
    return {
      datatable: null,
      fields: ['id',
      { key: "name", label: "nombre"},
      { key: "status", label: "estado"},
      { key: "created_at", label: "creado"},
      { key: 'actions', label: 'Ações' }

    ],
data:null,
      toExport:[],      
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult:null,
      pageToSearch:1,
      pageInfo:{},
      filterStatus: null,
      statuses: [{ id: "ENDED", name: 'Finalizado' },
        { id: 'WAITING', name: 'Esperando' },
        { id: 'OPEN', name: 'Abierta' },
        { id: 'STARTING', name: 'Por Comenzar' },
        { id: 'STARTED', name: 'Comenzado' },

      ],
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2 ? true : false
    }
  },
  methods:{
    search(){
      if(this.searchQuery == '' || !document.getElementById('live-query').getAttribute("aria-invalid")){
        this.$apollo.query({/**/
          query: gql`
            query{
  chat_rooms{
    id
    created_at
    name
    status
  }
}

          `,
          variables:{
//              offset : this.pageToSearch,
  //            status: this.filterStatus
          },
          client: "rouletteClient"
        }).then((data) => {
          // Result
          this.datatable = data.data.chat_rooms
        }).catch((error) => {
          console.error(error)
        })
      }
    },
    paginate(eV){
      if(this.pageToSearch == 1){
        this.pageToSearch = 2;

      }else{
        this.pageToSearch = this.pageToSearch + eV;
      }
      this.search()
    }
  },
  created(){
    this.search()
  }
}
</script>

<style>

</style>
